







const __sfc_main = {
  name: 'LandingImage'
};
__sfc_main.props = {
  landingImageUrl: {
    type: String,
    required: true
  }
};
export default __sfc_main;
