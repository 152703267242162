

















import { isEmpty } from 'lodash-es';
import { computed } from '@nuxtjs/composition-api';
import { Document } from '@contentful/rich-text-types';
import { ISeoFooter } from 'types/generated/contentful.d';
import RichTextRendererWithAnchors from '~/components/main/RichTextRendererWithAnchors.vue';
const __sfc_main = {
  name: 'SEOLinks'
};
__sfc_main.props = {
  seoFooter: {
    type: (Object as () => ISeoFooter),
    default: () => null
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const columnsDefined = computed<Document[]>(() => {
    if (!props.seoFooter) {
      return [];
    }

    return [(props.seoFooter.fields.column1 as Document), (props.seoFooter.fields.column2 as Document), (props.seoFooter.fields.column3 as Document)].filter(Boolean);
  });
  return {
    isEmpty,
    columnsDefined
  };
};

__sfc_main.components = Object.assign({
  RichTextRendererWithAnchors
}, __sfc_main.components);
export default __sfc_main;
