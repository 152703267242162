
























































import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    areasNotFound: { type: String, default: '' },
    areasWaitlistLinkLabel: { type: String, default: '' },
    areasWaitlistLinkUrl: { type: String, default: '' },
    ctaSlug: { type: String, default: '' },
    ctaText: { type: String, default: '' },
    headline: { type: String, default: '' },
    subheadline: { type: String, default: '' },
    tags: { type: Array as () => string[], default: () => [] },
    seeMore: { type: String, default: '' },
    seeLess: { type: String, default: '' },
  },

  setup() {
    const lottieOptions = ref()

    onMounted(async () => {
      const { default: loaded } = await import(
        '~/assets/animations/international-map.json'
      )

      lottieOptions.value = { animationData: loaded }
    })

    const isExpanded = ref(false)
    function toggleTagListDisplay() {
      isExpanded.value = !isExpanded.value
    }

    return {
      lottieOptions,
      toggleTagListDisplay,
      isExpanded,
    }
  },
})
