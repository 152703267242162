













































import FlinkLogo from '~/assets/logo.svg?inline'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    FlinkLogo,
  },
  props: {
    imageUrl: { type: String, default: '' },
    headline: { type: String, default: '' },
    text: { type: String, default: '' },
    imageTitle: { type: String, default: '' },
    bgImageAlt: { type: String, default: '' },
    ctaText: { type: String, default: '' },
    ctaSlug: { type: String, default: '' },
  },
  head: {},
})
