


























import { defineComponent } from '@nuxtjs/composition-api'
import { ILink } from '~/types/app/Links'

export default defineComponent({
  components: {},
  props: {
    imageUrl: { type: String, default: '' },
    headline: { type: String, default: '' },
    subheadline: { type: String, default: '' },
    imageTitle: { type: String, default: '' },
    imageAlt: { type: String, default: '' },
    appLinks: {
      type: Array as () => Array<ILink>,
      required: false,
      default: () => [],
    },
  },

  head: {},
})
