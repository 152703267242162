import { render, staticRenderFns } from "./index.vue?vue&type=template&id=84e19d94&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageBanner: require('/app/build/components/homepage/HomepageBanner.vue').default,Hero: require('/app/build/components/landing/Hero/Hero.vue').default,LandingCategoryCard: require('/app/build/components/landing/LandingCategoryCard/LandingCategoryCard.vue').default,LandingCategories: require('/app/build/components/landing/LandingCategories/LandingCategories.vue').default,LandingImage: require('/app/build/components/landing/LandingImage/LandingImage.vue').default,LandingUsp: require('/app/build/components/landing/LandingUSP/LandingUsp.vue').default,LandingUspContainer: require('/app/build/components/landing/LandingUSPContainer/LandingUspContainer.vue').default,MapSection: require('/app/build/components/landing/MapSection/MapSection.vue').default,RiderAcquisition: require('/app/build/components/landing/RiderAcquisition/RiderAcquisition.vue').default,AppDownloadSection: require('/app/build/components/landing/AppDownloadSection/AppDownloadSection.vue').default,SEOLinks: require('/app/build/components/landing/SEOLinks/SEOLinks.vue').default})
