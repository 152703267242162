













const __sfc_main = {
  name: 'HomepageBanner'
};
__sfc_main.props = {
  buttonLabel: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  }
};
export default __sfc_main;
