















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    categoryImgUrl: { type: String, required: false, default: '' },
    categoryTitle: { type: String, required: false, default: '' },
    categorySlug: { type: String, required: true, default: '/shop' },
  },
})
