import {
  Ref,
  ref,
  useContext,
  useFetch,
  useMeta,
  useRoute,
} from '@nuxtjs/composition-api'
import contentfulClient from '~/lib/contentfulClient'
import { MetaInfo } from 'vue-meta'
import { IPageWrapperBase } from '~/types/contentful/Pages'
import { CF_NESTED_REFFERENCES } from '~/lib/constants'

export default function useHomepage<T extends IPageWrapperBase>(): Ref<
  T['localizedPage']
> {
  const { $throwError, i18n, $structuredData } = useContext()
  const route = useRoute().value
  const homepageContentfulId = '16nHRWlkdhQFjhvDG2dXeJ'

  const entry = ref() as Ref<T['localizedPage']>

  useFetch(async () => {
    const entries = await contentfulClient(route.query.mode).getEntries<T>({
      'sys.id': homepageContentfulId,
      locale: i18n.locale,
      include: CF_NESTED_REFFERENCES,
    })

    if (
      !entries?.items ||
      entries.items.length === 0 ||
      !entries.items[0].fields.localizedPage
    ) {
      $throwError(404, 'Not available home!')
      return
    }
    entry.value = entries.items[0].fields.localizedPage
  })

  useMeta(() => {
    const { value } = entry
    if (!value) {
      return {} as MetaInfo
    }

    const metaData: MetaInfo['meta'] = [
      {
        property: 'og:title',
        content: `${value.fields.metaTitle} | Flink`,
      },
      {
        property: 'og:url',
        content: `https://www.goflink.com${route.path}`,
      },
      {
        property: 'og:image',
        content:
          `https:${value.fields.ogimage?.fields.file.url}` ||
          'https://www.goflink.com/flink.jpg',
      },
    ]

    if (value.fields.metaDescription) {
      metaData.push({
        hid: 'description',
        name: 'description',
        content: value.fields.metaDescription || '',
      })
    }

    return {
      title: `${value.fields.metaTitle} | Flink`,
      meta: metaData,
      script: [
        $structuredData.script({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+491741939374',
              contactType: 'customer service',
            },
          ],
          name: 'Flink SE',
          sameAs: [
            'https://www.facebook.com/FlinkGermany/',
            'https://www.instagram.com/flinkgermany/',
          ],
          url: 'https://www.goflink.com/',
        }),
      ],
    }
  })

  return entry
}
