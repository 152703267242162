














const __sfc_main = {
  name: 'LandingUspContainer'
};
__sfc_main.props = {
  title: {
    type: String,
    default: ''
  },
  ctaText: {
    type: String,
    default: ''
  },
  ctaSlug: {
    type: String,
    default: ''
  }
};
export default __sfc_main;
