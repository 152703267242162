










const __sfc_main = {
  name: 'LandingUsp'
};
__sfc_main.props = {
  uspIconUrl: {
    type: String,
    default: ''
  },
  uspTitle: {
    type: String,
    default: ''
  },
  uspParagraph: {
    type: String,
    default: ''
  }
};
export default __sfc_main;
