import { render, staticRenderFns } from "./SEOLinks.vue?vue&type=template&id=5133a912&scoped=true&"
import script from "./SEOLinks.vue?vue&type=script&lang=ts&"
export * from "./SEOLinks.vue?vue&type=script&lang=ts&"
import style0 from "./SEOLinks.vue?vue&type=style&index=0&id=5133a912&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5133a912",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RichTextRendererWithAnchors: require('/app/build/components/main/RichTextRendererWithAnchors.vue').default})
