import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=75218d52&scoped=true&"
import script from "./Hero.vue?vue&type=script&lang=ts&"
export * from "./Hero.vue?vue&type=script&lang=ts&"
import style0 from "./Hero.vue?vue&type=style&index=0&id=75218d52&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75218d52",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/app/build/components/ContentfulImage/ContentfulImage.vue').default})
