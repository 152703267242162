
































































































































































import AppDownloadSection from '~/components/landing/AppDownloadSection/AppDownloadSection.vue'
import Hero from '~/components/landing/Hero/Hero.vue'
import HomepageBanner from '~/components/homepage/HomepageBanner.vue'
import LandingCategories from '~/components/landing/LandingCategories/LandingCategories.vue'
import LandingCategoryCard from '~/components/landing/LandingCategoryCard/LandingCategoryCard.vue'
import LandingImage from '~/components/landing/LandingImage/LandingImage.vue'
import LandingUsp from '~/components/landing/LandingUSP/LandingUsp.vue'
import LandingUspContainer from '~/components/landing/LandingUSPContainer/LandingUspContainer.vue'
import RiderAcquisition from '~/components/landing/RiderAcquisition/RiderAcquisition.vue'
import MapSection from '~/components/landing/MapSection/MapSection.vue'
import SEOLinks from '~/components/landing/SEOLinks/SEOLinks.vue'
import { hasAllDefined, linksArr } from '~/lib/helpers'

import useHomepage from '~/hooks/useHomepage'
import { IHomepageWrapperFields } from '~/types/generated/contentful'

import {
  computed,
  defineComponent,
  ref,
  useContext,
} from '@nuxtjs/composition-api'
import { EComponentName, EScreenName } from '~/lib/segment'

export default defineComponent({
  components: {
    AppDownloadSection,
    Hero,
    HomepageBanner,
    LandingCategories,
    LandingCategoryCard,
    LandingImage,
    LandingUsp,
    LandingUspContainer,
    RiderAcquisition,
    MapSection,
    SEOLinks,
  },

  layout: 'landing',

  setup() {
    const { $segmentEvent } = useContext()
    const hour = ref<number>(new Date().getHours())
    const entry = useHomepage<IHomepageWrapperFields>()

    const heroImage = computed(() => {
      if (hour.value >= 5 && hour.value < 11) {
        return entry.value.fields.heroImageBreakfast
      } else if (hour.value >= 11 && hour.value < 16) {
        return entry.value.fields.heroImageLunch
      } else if (hour.value >= 16 && hour.value < 21) {
        return entry.value.fields.heroImageDinner
      } else {
        return entry.value.fields.heroImageSnacks
      }
    })

    const cities = computed(() => {
      return entry.value.fields.mapSection?.fields.tags?.map(
        (tag) => tag.fields.tagName ?? ''
      )
    })

    const map = computed(() => {
      return entry.value.fields.mapSection?.fields
    })

    function handleCtaClick(
      componentContent: string,
      componentValue: number
    ): void {
      $segmentEvent.CTA_CLICKED({
        componentContent,
        componentName: EComponentName.cta,
        componentValue,
        screenName: EScreenName.home,
      })
    }

    function handleCategoryClick(
      componentContent: string,
      componentValue: string
    ): void {
      $segmentEvent.CATEGORY_CLICKED({
        componentContent,
        componentValue,
        componentName: EComponentName.categories,
        screenName: EScreenName.home,
      })
    }

    return {
      entry,
      handleCategoryClick,
      handleCtaClick,
      linksArr,
      hasAllDefined,
      heroImage,
      cities,
      map,
    }
  },
  head: {},
})
