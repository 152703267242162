import { render, staticRenderFns } from "./LandingCategoryCard.vue?vue&type=template&id=0d182e33&scoped=true&"
import script from "./LandingCategoryCard.vue?vue&type=script&lang=ts&"
export * from "./LandingCategoryCard.vue?vue&type=script&lang=ts&"
import style0 from "./LandingCategoryCard.vue?vue&type=style&index=0&id=0d182e33&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d182e33",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/app/build/components/ContentfulImage/ContentfulImage.vue').default})
