import { render, staticRenderFns } from "./AppDownloadSection.vue?vue&type=template&id=6727fc42&scoped=true&"
import script from "./AppDownloadSection.vue?vue&type=script&lang=ts&"
export * from "./AppDownloadSection.vue?vue&type=script&lang=ts&"
import style0 from "./AppDownloadSection.vue?vue&type=style&index=0&id=6727fc42&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6727fc42",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StoreBadge: require('/app/build/components/StoreBadge/StoreBadge.vue').default,ContentfulImage: require('/app/build/components/ContentfulImage/ContentfulImage.vue').default})
