





























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {},
  props: {
    headline: { type: String, default: '' },
    subheadline: { type: String, default: '' },
    imageUrl: { type: String, default: '' },
    imageTitle: { type: String, default: '' },
    imageAlt: { type: String, default: '' },
    applyCtaText: { type: String, default: '' },
    applyCtaSlug: { type: String, default: '' },
  },
})
