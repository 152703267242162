















const __sfc_main = {
  name: 'LandingCategories'
};
__sfc_main.props = {
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  ctaText: {
    type: String,
    default: ''
  },
  ctaSlug: {
    type: String,
    default: '/shop'
  }
};
export default __sfc_main;
