var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.entry && _vm.entry.fields)?[(_vm.entry.fields.recruitingBanner && !_vm.$store.getters.getIsKiosk)?_c('HomepageBanner',_vm._b({},'HomepageBanner',_vm.entry.fields.recruitingBanner.fields,false)):_vm._e(),_vm._v(" "),_c('div',{staticClass:"home__content"},[(
          _vm.heroImage &&
          _vm.hasAllDefined([
            _vm.entry.fields.heroHeadline,
            _vm.entry.fields.heroSubheadline ])
        )?_c('Hero',{attrs:{"headline":_vm.entry.fields.heroHeadline,"text":_vm.entry.fields.heroSubheadline,"image-url":_vm.heroImage.fields.file.url,"image-title":_vm.heroImage.fields.title,"bg-image-alt":_vm.heroImage.fields.description,"cta-text":_vm.entry.fields.heroCtaCopy,"cta-slug":_vm.entry.fields.heroCtaSlug},on:{"cta-click":function($event){return _vm.handleCtaClick(_vm.entry.fields.heroCtaCopy, 1.1)}}}):_vm._e(),_vm._v(" "),(
          _vm.hasAllDefined([
            _vm.entry.fields.categoryHeadline,
            _vm.entry.fields.categorySubheadline,
            _vm.entry.fields.categoryCtaCopy,
            _vm.entry.fields.categoryCtaSlug ])
        )?_c('LandingCategories',{attrs:{"title":_vm.entry.fields.categoryHeadline,"sub-title":_vm.entry.fields.categorySubheadline,"cta-text":_vm.entry.fields.categoryCtaCopy,"cta-slug":_vm.entry.fields.categoryCtaSlug},on:{"cta-click":function($event){return _vm.handleCtaClick(_vm.entry.fields.categoryCtaCopy, 2.1)}}},_vm._l((_vm.entry.fields.categoryTiles),function(categoryCard,index){return _c('LandingCategoryCard',{key:categoryCard.fields.categoryTitle,attrs:{"category-img-url":categoryCard.fields.categoryImage.fields.file.url,"category-title":categoryCard.fields.categoryTitle,"category-slug":categoryCard.fields.categorySlug},on:{"category-click":function($event){return _vm.handleCategoryClick(
              categoryCard.fields.categoryTitle,
              ("4." + index)
            )}}})}),1):_vm._e(),_vm._v(" "),(
          _vm.entry.fields.landingImage &&
          _vm.hasAllDefined(Object.values(_vm.entry.fields.landingImage.fields))
        )?_c('LandingImage',{attrs:{"landing-image-url":_vm.entry.fields.landingImage.fields.file.url}}):_vm._e(),_vm._v(" "),(
          _vm.entry &&
          _vm.entry.fields.uspSection &&
          _vm.hasAllDefined([
            _vm.entry.fields.uspHeadline,
            _vm.entry.fields.uspCtaCopy,
            _vm.entry.fields.uspCtaSlug ])
        )?_c('LandingUspContainer',{attrs:{"title":_vm.entry.fields.uspHeadline,"cta-text":_vm.entry.fields.uspCtaCopy,"cta-slug":_vm.entry.fields.uspCtaSlug},on:{"cta-click":function($event){return _vm.handleCtaClick(_vm.entry && _vm.entry.fields.uspCtaCopy, 3.1)}}},_vm._l((_vm.entry.fields.uspSection.fields.uspTiles),function(usp){return _c('LandingUsp',{key:usp.fields.uspTitle || '',attrs:{"usp-icon-url":usp.fields.uspIcon.fields.file.url,"usp-title":usp.fields.uspTitle || '',"usp-paragraph":usp.fields.uspParagraph}})}),1):_vm._e(),_vm._v(" "),(
          _vm.entry &&
          _vm.entry.fields.mapSection &&
          _vm.hasAllDefined(Object.values(_vm.map))
        )?_c('MapSection',{attrs:{"headline":_vm.map.mapSectionHeadline,"subheadline":_vm.map.mapSectionSubheadline,"areas-not-found":_vm.map.mapSectionAreasNotFound,"areas-waitlist-link-label":_vm.map.mapSectionAreasWaitlistLinkLabel,"areas-waitlist-link-url":_vm.map.mapSectionAreasWaitlistLinkUrl,"cta-text":_vm.map.mapSectionCtaText,"cta-slug":_vm.map.mapSectionCtaSlug,"tags":_vm.cities,"see-more":_vm.map.mapSectionSeeMore,"see-less":_vm.map.mapSectionSeeLess}}):_vm._e(),_vm._v(" "),(
          _vm.entry &&
          _vm.entry.fields.riderAcquisition &&
          _vm.hasAllDefined(Object.values(_vm.entry.fields.riderAcquisition.fields))
        )?_c('RiderAcquisition',{attrs:{"headline":_vm.entry.fields.riderAcquisition.fields.riderAcquisitionHeadline,"subheadline":_vm.entry.fields.riderAcquisition.fields.riderAcquisitionSubheadline,"image-url":_vm.entry.fields.riderAcquisition.fields.riderAcquisitionImage.fields
            .file.url,"image-title":_vm.entry.fields.riderAcquisition.fields.riderAcquisitionImage.fields
            .file.title,"image-alt":_vm.entry.fields.riderAcquisition.fields.riderAcquisitionImage.fields
            .file.description,"apply-cta-text":_vm.entry.fields.riderAcquisition.fields.applyCtaText,"apply-cta-slug":_vm.entry.fields.riderAcquisition.fields.applyCtaSlug}}):_vm._e(),_vm._v(" "),(
          _vm.entry &&
          _vm.entry.fields.appDownloadSection &&
          _vm.hasAllDefined(Object.values(_vm.entry.fields.appDownloadSection.fields))
        )?_c('AppDownloadSection',{attrs:{"headline":_vm.entry.fields.appDownloadSection.fields.appDownloadHeadline,"subheadline":_vm.entry.fields.appDownloadSection.fields.appDownloadSubheadline,"image-url":_vm.entry.fields.appDownloadSection.fields.appDownloadImage.fields.file
            .url,"image-title":_vm.entry.fields.appDownloadSection.fields.appDownloadImage.fields.file
            .title,"image-alt":_vm.entry.fields.appDownloadSection.fields.appDownloadImage.fields.file
            .description,"app-links":_vm.linksArr(_vm.entry.fields.appDownloadSection.fields.appLinks)}}):_vm._e(),_vm._v(" "),_c('SEOLinks',{attrs:{"seo-footer":_vm.entry.fields.seoFooter}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }